.container_1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 90rem;
  margin: 0 auto;
  padding: 1rem;
  gap: 1.5rem;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.search-bar {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  align-items: center;
  margin-bottom: 2rem;
  width: 530px;
  border-radius: 8px;
}

.search-input-container {
  display: flex;
  align-items: center;
  position: relative;
  padding: 1rem 1.5rem;
  height: 40px;
  width: 100%;
  max-width: 600px;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
}

.search-icon {
  font-size: 1.5rem;
  color: #555;
}

.search-input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 1.25rem;
  color: #333;
  background-color: transparent;
}

.search-button {
  background-color: #0b678b;
  height: 40px;
  width: 100px;
  border-radius: 8px;
  color: #ffffff;
}

.template-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.card_2 {
  position: relative;
  padding: 1rem;
  transition: box-shadow 0.1s;
  cursor: pointer;
  width: 500px;
  height: 55px;
  border: 2px solid #0b678b;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card_2:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.card-title {
  font-size: 1.02rem;
  font-weight: 600;
}

.card-icon_2 {
  height: 1.5rem;
  width: 1.5rem;
  color: black;
  transition: color 0.5s;
  font-size: 20px;
  margin-left: 8px;
}

.card_2:hover .card-icon_2 {
  transform: scale(1.05);
  color: #374151;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  .container_1 {
    padding: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 90rem;
    margin: 0 auto;
    padding: 1rem;
    gap: 1.5rem;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  .search-bar {
    flex-direction: column;
    width: 60%;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  .search-input-container {
    width: 100%;
    max-width: 100%;
    padding: 0.75rem 1rem;
    height: auto;
  }

  .search-button {
    width: 100%;
    height: auto;
    padding: 0.75rem;
    font-size: 1rem;
  }

  .template-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .card_2 {
    width: 100%;
    height: auto;
  }

  .card-title {
    font-size: 1rem;
  }

  .pagination-container {
    margin-top: 2rem;
  }
}

@media (max-width: 768px) {
  .container_1 {
    padding: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 90rem;
    margin: 0 auto;
    padding: 1rem;
    gap: 1.5rem;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  .search-bar {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  .search-input-container {
    width: 100%;
    max-width: 100%;
    padding: 0.75rem 1rem;
    height: auto;
  }

  .search-button {
    width: 100%;
    height: auto;
    padding: 0.75rem;
    font-size: 1rem;
  }

  .template-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .card_2 {
    width: 100%;
    height: auto;
  }

  .card-title {
    font-size: 1rem;
  }

  .pagination-container {
    margin-top: 1rem;
  }
}

@media (max-width: 480px) {
  .search-bar {
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  .search-input-container {
    padding: 0.5rem 0.75rem;
  }

  .search-input {
    font-size: 1rem;
  }

  .search-button {
    font-size: 0.9rem;
    padding: 0.5rem;
  }

  .card-title {
    font-size: 0.9rem;
  }

  .card-icon_2 {
    font-size: 1.2rem;
  }

  .container_1 {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 90rem;
    margin: 0 auto;
    padding: 1rem;
    gap: 1.5rem;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  .card_2 {
    width: 300px;
    height: 55px;
  }

  .pagination-container {
    margin-top: 1.5rem;
  }
}

.back-button_1 {
  position: absolute;
  top: 113px;
  left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #005186;
  text-decoration: none;
  height: 70px;
  width: 70px;
}

.back-icon {
  margin-right: 8px;
  width: 48px;
  height: 48px;
}
