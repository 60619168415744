.testim {
    width: 100%;
     display: grid;
     place-items: center;
}

.testim .wrap {
position: relative;
width: 100%;
max-width: 1020px;
  display: flex;
  flex-direction: column;
  row-gap: 4em;
}

.testim .arrow {
display: block;
position: absolute;
color: #333;
cursor: pointer;
font-size: 2em;
top: 50%;
-webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
-webkit-transition: all .3s ease-in-out;    
-ms-transition: all .3s ease-in-out;    
-moz-transition: all .3s ease-in-out;    
-o-transition: all .3s ease-in-out;    
transition: all .3s ease-in-out;
padding: 5px;
z-index: 22222222;
}

.testim .arrow:before {
    cursor: pointer;
}

.testim .arrow:hover {
color: var(--color_1);
}


.testim .arrow.left {
left: 70px;
}

.testim .arrow.right {
right: 70px;
}

.testim .dots {
text-align: center;
width: 100%;
bottom: 60px;
left: 0;
display: block;
    height: 12px;
}

.testim .dots .dot {
list-style-type: none;
display: inline-block;
width: 12px;
height: 12px;
border-radius: 50%;
border: 1px solid var(--color_1);
margin: 0 10px;
cursor: pointer;
-webkit-transition: all .5s ease-in-out;    
-ms-transition: all .5s ease-in-out;    
-moz-transition: all .5s ease-in-out;    
-o-transition: all .5s ease-in-out;    
transition: all .5s ease-in-out;
    position: relative;
}

.testim .dots .dot.active,
.testim .dots .dot:hover {
background: var(--color_1);
border-color: var(--color_1);
}

.testim .dots .dot.active {
-webkit-animation: testim-scale .5s ease-in-out forwards;   
-moz-animation: testim-scale .5s ease-in-out forwards;   
-ms-animation: testim-scale .5s ease-in-out forwards;   
-o-animation: testim-scale .5s ease-in-out forwards;   
animation: testim-scale .5s ease-in-out forwards;   
}

.testim .cont {
position: relative;
    overflow: hidden;
    display: grid;
  place-items: center
}

.testim .cont > div {
position: absolute;
top: 0;
left: 0;
opacity: 0;
}

.testim .cont > div.inactive {
opacity: 1;
}


.testim .cont > div.active {
position: relative;
opacity: 1;
}
 .tesminol_wrap{
    width: 100%;
    place-items: center;
    display: grid;
 }
.testimonal_card{
    border: 1px solid var(--color_2);
    width: 80%;
    min-height: 250px;
    border-radius: 20px;
    padding: 1em;
    min-width: 320px;
    /* background: rgb(0,81,134);
    background: linear-gradient(90deg, rgba(0,81,134,0.5) 14%, rgba(255,179,77,0.6822128509606968) 100%);  */
}
.testimonal_card span{
    color: #404040;
text-align: justify;
font-family: Montserrat;
font-size: 5vw;
font-style: normal;
font-weight: 600;
}
.testimonal_card p{
    margin-top: -40px;
    line-height: 160%;
    letter-spacing: 1px;
   
}
.acceptCookie{
    font-size: 9px;
    height: auto;
    padding: .5em;

}
.cookies_wrap{
    width: 25vw;
    position: fixed;
    top: 84vh;
    background-color: #fff;
    display: flex;
    z-index: 9999;
    justify-content: center;
    align-items: center;
    right: 2em;
    padding: 1em;
    border-radius: 20px;
}
.cookies_wrap p {
    width: 90%;
    font-size: 9px;
}
.cookies_wrap p a {
     color: var(--color_1);
}


@-webkit-keyframes testim-scale {
0% {
    -webkit-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
}

35% {
    -webkit-box-shadow: 0px 0px 10px 5px #eee;        
    box-shadow: 0px 0px 10px 5px #eee;        
}

70% {
    -webkit-box-shadow: 0px 0px 10px 5px #ea830e;        
    box-shadow: 0px 0px 10px 5px #ea830e;        
}

100% {
    -webkit-box-shadow: 0px 0px 0px 0px #ea830e;        
    box-shadow: 0px 0px 0px 0px #ea830e;        
}
}

@-moz-keyframes testim-scale {
0% {
    -moz-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
}

35% {
    -moz-box-shadow: 0px 0px 10px 5px #eee;        
    box-shadow: 0px 0px 10px 5px #eee;        
}

70% {
    -moz-box-shadow: 0px 0px 10px 5px #ea830e;        
    box-shadow: 0px 0px 10px 5px #ea830e;        
}

100% {
    -moz-box-shadow: 0px 0px 0px 0px #ea830e;        
    box-shadow: 0px 0px 0px 0px #ea830e;        
}
}

@-ms-keyframes testim-scale {
0% {
    -ms-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
}

35% {
    -ms-box-shadow: 0px 0px 10px 5px #eee;        
    box-shadow: 0px 0px 10px 5px #eee;        
}

70% {
    -ms-box-shadow: 0px 0px 10px 5px #ea830e;        
    box-shadow: 0px 0px 10px 5px #ea830e;        
}

100% {
    -ms-box-shadow: 0px 0px 0px 0px #ea830e;        
    box-shadow: 0px 0px 0px 0px #ea830e;        
}
}

@-o-keyframes testim-scale {
0% {
    -o-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
}

35% {
    -o-box-shadow: 0px 0px 10px 5px #eee;        
    box-shadow: 0px 0px 10px 5px #eee;        
}

70% {
    -o-box-shadow: 0px 0px 10px 5px #ea830e;        
    box-shadow: 0px 0px 10px 5px #ea830e;        
}

100% {
    -o-box-shadow: 0px 0px 0px 0px #ea830e;        
    box-shadow: 0px 0px 0px 0px #ea830e;        
}
}

@keyframes testim-scale {
0% {
    box-shadow: 0px 0px 0px 0px #eee;
}

35% {
    box-shadow: 0px 0px 10px 5px #eee;        
}

70% {
    box-shadow: 0px 0px 10px 5px #ea830e;        
}

100% {
    box-shadow: 0px 0px 0px 0px #ea830e;        
}
}

@-webkit-keyframes testim-content-in {
from {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
}

to {
    opacity: 1;
    -webkit-transform: translatex(0);        
    transform: translateX(0);        
}
}

@-moz-keyframes testim-content-in {
from {
    opacity: 0;
    -moz-transform: translateX(100%);
    transform: translateY(100%);
}

to {
    opacity: 1;
    -moz-transform: translateX(0);        
    transform: translateX(0);        
}
}

@-ms-keyframes testim-content-in {
from {
    opacity: 0;
    -ms-transform: translateX(100%);
    transform: translatex(100%);
}

to {
    opacity: 1;
    -ms-transform: translateX(0);        
    transform: translateX(0);        
}
}

@-o-keyframes testim-content-in {
from {
    opacity: 0;
    -o-transform: translateX(100%);
    transform: translateX(100%);
}

to {
    opacity: 1;
    -o-transform: translateX(0);        
    transform: translateX(0);        
}
}

@keyframes testim-content-in {
from {
    opacity: 0;
    transform: translateX(100%);
}

to {
    opacity: 1;
    transform: translateX(0);        
}
}

@-webkit-keyframes testim-content-out {
from {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

to {
    opacity: 0;
    -webkit-transform: translateX(-100%);        
    transform: translateX(-100%);        
}
}

@-moz-keyframes testim-content-out {
from {
    opacity: 1;
    -moz-transform: translateX(0);
    transform: translateX(0);
}

to {
    opacity: 0;
    -moz-transform: translateX(-100%);        
    transform: translateX(-100%);        
}
}

@-ms-keyframes testim-content-out {
from {
    opacity: 1;
    -ms-transform: translateX(0);
    transform: translateX(0);
}

to {
    opacity: 0;
    -ms-transform: translateX(-100%);        
    transform: translateX(-100%);        
}
}

@-o-keyframes testim-content-out {
from {
    opacity: 1;
    -o-transform: translateX(0);
    transform: translateX(0);
}

to {
    opacity: 0;
    transform: translateX(-100%);        
    transform: translateX(-100%);        
}
}

@keyframes testim-content-out {
from {
    opacity: 1;
    transform: translateX(0);
}

to {
    opacity: 0;
    transform: translateX(-100%);        
}
}

@-webkit-keyframes testim-show {
from {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

to {
    opacity: 1;
    -webkit-transform: scale(1);       
    transform: scale(1);       
}
}

@-moz-keyframes testim-show {
from {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
}

to {
    opacity: 1;
    -moz-transform: scale(1);       
    transform: scale(1);       
}
}

@-ms-keyframes testim-show {
from {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
}

to {
    opacity: 1;
    -ms-transform: scale(1);       
    transform: scale(1);       
}
}

@-o-keyframes testim-show {
from {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
}

to {
    opacity: 1;
    -o-transform: scale(1);       
    transform: scale(1);       
}
}

@keyframes testim-show {
from {
    opacity: 0;
    transform: scale(0);
}

to {
    opacity: 1;
    transform: scale(1);       
}
}

@-webkit-keyframes testim-hide {
from {
    opacity: 1;
    -webkit-transform: scale(1);       
    transform: scale(1);       
}

to {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
}

@-moz-keyframes testim-hide {
from {
    opacity: 1;
    -moz-transform: scale(1);       
    transform: scale(1);       
}

to {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
}
}

@-ms-keyframes testim-hide {
from {
    opacity: 1;
    -ms-transform: scale(1);       
    transform: scale(1);       
}

to {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
}
}

@-o-keyframes testim-hide {
from {
    opacity: 1;
    -o-transform: scale(1);       
    transform: scale(1);       
}

to {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
}
}

@keyframes testim-hide {
from {
    opacity: 1;
    transform: scale(1);       
}

to {
    opacity: 0;
    transform: scale(0);
}
}


@media screen and (max-width:600px) {
    .cookies_wrap{
        width: 80%;
        gap: 1em;
    }
 
}
@media all and (max-width: 300px) {
    body {
        font-size: 14px;
    }
    }