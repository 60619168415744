
@keyframes fadeIn {
    0% {
      opacity: 0;
      transition: opacity 1.5s;
    }
    100% {
      opacity: 1;
    }
  }
  .wrapper {
    width: 100%;
    max-width: 1100px;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding: 40px 2em;
    margin:auto;
  }
  .timeline_main_wrap{
     width: 100%;
     display: grid;
     grid-template-columns: 70% 50%;

  }
  .timeline_content h2{
    color: var(--Presolv360-Blue, #005186);
font-family: Montserrat;
font-size: 2.5vw;
font-style: normal;
font-weight: 600;
line-height: 140%; /* 67.2px */
  }
  .timeline_content p {
    color: #262626;
font-family: Montserrat;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 160%; /* 38.4px */
   }
   .timeline_main_wrap img{
     width: 80%;
   }
  @media (min-width: 900px) {
    .wrapper {
      padding:0;
    }
  }

  
  .timeline {
    width: 90%;
    padding: 0 35px;
    margin-top: 70px;
  }
  @media (min-width: 900px) {
    .timeline {
      padding: 0;
    }
  }
  .timeline ul {
    padding: 0px;
    height: 3px;
    background: #005186;
    border-radius: 3px;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
  }
  .timeline ul li:first-child a {
    align-items: start;
  }
  .timeline ul li:last-child a {
    align-items: end;
  }
  .timeline ul li {
    list-style: none;
    cursor: pointer;
  }
  .timeline ul li button {
    text-decoration: none;
    text-align: center;
    font-family: Montserrat; 
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    color: #005186;
    margin-top: 23px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
    background: none;
  border: none;
  cursor: pointer;
  }
  .steps_indicator{
    display: flex;
  position: absolute;
  left: 0px;
  top: 0px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  color: #FBB04C;
  }
  @media (min-width: 900px) {
    .timeline ul li button {
      font-size: 24px;
    }
  }
  .timeline ul li button::before {
    content: '' ;
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    background: #005186;
    border-radius: 50%;
    margin-bottom: 20px;
    color: #FBB04C;
  }
  .timeline ul li.active button::before {
    background:#FBB04C ;
    color: #005186;
  }
  .timeline ul li.active button span{
     color: #005186;
  }
  .Timeline_panel {
    display: flex;
  }
  
  .arrows {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 30px;
    margin-top: 10%
  }
  .arrows button {
    cursor: pointer;
    background: transparent;
    border: none;
  }
  
  .content {
    margin-top: 20px;
    width: 100%;
  }
  .content .tab {
    display: none;
    padding: 0px 20px;
    color: black;
    height: 300px;
  }

  .content .tab.selected {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    animation: 1s ease-out 0s 1 fadeIn;
  }
.timeline_content{
     display: flex;
     flex-direction: column;
     justify-content: center;
     row-gap: 1em;
}