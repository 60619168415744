
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800&display=swap');

:root{
  --text_font :'Montserrat', sans-serif;
  --gradient_url:url(./assets/Images/Gradient.png);
  --gradient_url_mobile:url(./assets/Images/Mobile_Gradient.png);
  --color_1:#005186;
  --color_2:#FBB04C;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--text_font);
}
h3{
  font-weight: 600;
}
html{
  scroll-behavior: smooth;
}
body{
  background-color: #ffff;
}
li{
  list-style: none;
}
a{
  cursor: pointer;
}
.main{
  width: 100%;
  position: relative;
  /* background-image: var( --gradient_url); */
}
.flex_row{
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  gap:7%;
  justify-content: center;
}

.navbar {
  width: 100%;
-webkit-backdrop-filter: blur(5px);
  position: sticky;
  top: 0;
  padding: 1.5em 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  z-index: 99;
  background: rgba(255, 255, 255, 0.10);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px); 
  background-image: var(--gradient_url);
  background-size: 100%;
  /* background-size: contain;
  background-repeat: no-repeat; */
}

.logo {
  width: 16%;
  min-width: 150px;
  position: absolute;
  left: 4%;
}
.logo img{
  width: 80%;
  height: auto;
}

.menu-icon {
  display: none;
}



.nav-elements ul {
  display: flex;
  gap: 1.5em;
  list-style-type: none;
  align-items: center;
}


.nav-elements ul a {
   font-family: var(--text_font);
  color: #717171;
  text-decoration: none;
   font-size: 1.1vw;
   font-weight: 600;
}

.nav-elements ul a.active {
  color: var(--color_1);
  font-weight: 700;
  position: relative;

}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--color_1);
}
#nav_enq_btn{
  visibility: hidden;
 transition: 1s all ease-in;
}
.intl-tel-input .country-list{
  width: 100%;
   max-height: 100px !important;
   overflow-x: hidden;
}
.modal_main_wrap input{
    height: 40px;
    width: 100%;
    padding-left: 1em;
}
.intl-tel-input .flag-container .arrow{
  display: none;
}
.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code{
  padding-left: 37px !important;
}
.iti-flag{
  position: absolute;
  top: 12px;
  left: 10px;
}
.login_drp_dwn_wrap{
  position: absolute;
  right: 0;
  transform: translateY(50%);
  background-color: #fff;
  border-radius: 8px;
  transition: 1s ease-in all;
  box-shadow: rgba(0, 0, 0, 0.25) 4px 4px 24px 8px;
  margin-right: 4%;
}
.login_drp_dwn_wrap li a{
    text-decoration: none;
    color:#717171
}
.login_drp_dwn_wrap li a:hover{
   color: #fff;
}
.login_drp_dwn_wrap li:hover{
  background-color: #005186;
}
.login_drp_dwn_wrap li{
  padding:.5em 1em;
   border-bottom: 1px solid #717171;
    color:#717171
}
.login_drp_dwn_wrap li:last-child{
  border-bottom: none;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
  .about_slide_main_wrap{
     flex-direction: column;
     justify-content: center;
     align-items: center;
  }
  .about_slide_img_wrap{
    width: 80%;
  }
}


@media (max-width: 1061px) {
  .navbtn_grp{
    display: none;
  }
   .navbar{
    height: 60px;
    padding: 0;
   }
  
  .logo{
    position: absolute;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 160px;
    top: 50%;
  }
  .menu-icon {
    display: block;
    cursor: pointer;
    position: absolute;
  left: 0;
  }

  .nav-elements {
    position: absolute;
    left: 0;
    top: 60px;
    background-color: #fef7e5;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }
  .true{
  width: 200px;
  }
  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    padding-left: 2.5em;
    align-items: flex-start;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
  .nav-elements ul a{
    font-size: 3vw;
  }
}
.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 0.5s all ease;
}

.reveal.active_word {
  transform: translateY(0);
  opacity: 1;
}