.event_gallery_wrap {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1em;
  padding: 4em;
  width: 80%;
  margin: auto;
  cursor: url("../assets/Images/Vector.png"), auto;
}
.gallery_child {
  border-radius: 16px;
  background: #005186;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  color: #ffff;
  object-fit: cover;
  display: grid;
  place-items: center;
  padding: 1em;
  gap: 0.5em;
}
.gallery_child:first-child,
.gallery_child:last-child {
  background-color: var(--color_1);
}
.gallery_child:nth-child(2),
.gallery_child:nth-child(3) {
  background-color: var(--color_2);
}
.gallery_child img {
  width: 100%;
}
.gallery_child p {
  font-weight: 600;
}
.advisory_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.advisory_wrap .guide_card {
  width: 340px;
}
.job_card_wrap a {
  text-decoration: none;
  color: #454545;
}
li.dropdown {
  display: inline-block;
  font-family: var(--text_font);
}
.dropbtn {
  font-family: var(--text_font);
  font-weight: 600;
  font-size: 1.1vw;
  color: #717171;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(207, 161, 161, 0.2);
  border-radius: 16px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 12px !important;
}

.dropdown-content a:hover {
  background-color: var(--color_1);
  color: #fff;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.newsletter_wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1em;
  padding-bottom: 2em;
}
.newsletter_wrap h3 {
  color: var(--Presolv360-Blue, #005186);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 67.2px */
}
.newsletter_wrap p {
  color: #262626;
  text-align: center;
  /* Text Style 1 */
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  width: 60%;
}
.newsletter_wrap input {
  border-radius: 8px;
  border: 2px solid #fbb04c;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  padding: 1em;
  text-align: center;
}
.casedetailwrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 2em;
}
.casedetailwrap img {
  width: 70%;
}
.casedetailwrap h3 {
  color: #005186;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -1.8px;
}
.casedetailwrap p {
  color: #262626;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 38.4px */
}

.advisoryBord .guide_card img {
  width: 70%;
}
.founders .guide_card img {
  width: 70%;
}
.no_wrap {
  white-space: nowrap;
}
