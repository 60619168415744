.pagination {
  display: flex;
  border: 2px solid var(--color_1);
  width: max-content;
}
.page-item {
  flex: 1;
  padding: 0.5em 1em;
  background-color: var(--color_1);
  overflow: hidden;
  transition: 1s all;
  color: #fff;
}
.pagination .active {
  background-color: var(--color_2);
}
.poadcast_main_wrap {
  display: flex;
  flex-direction: column;
  row-gap: 6em;
  padding: 2em;
  justify-content: center;
  align-items: center;
}
.settel_wrap h2 {
  color: var(--Presolv360-Blue, #005186);
  font-family: Montserrat;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  padding-bottom: 1em;
}
.settel_wrap p {
  line-height: 140%;
}
.settel_wrap {
  padding: 2em 10em;
}
.odrtvnav {
  width: 25%;
  border-radius: 32px;
  border: 2px solid var(--color_1);
  display: flex;
  position: relative;
  min-width: 280px;
}
.highlight_tv {
  color: #fff;
  border-radius: 32px;
  background-color: #005186;
  width: calc(100% / 2);
  position: absolute;
  height: 102%;
  transition: 0.25s all ease-in;
}
.odrtvnav li {
  flex: 1 1;
  color: #005186;
  list-style: none;
  font-family: Montserrat;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  text-align: center;
  transition: 0.25s all ease-in;
  cursor: pointer;
  z-index: 3;
  padding: 0.5em;
}
.odrtvnav .active {
  color: #fff;
}
.thumbnail {
  border-radius: 12px;
}
.globalSpeaking_page_wrap {
  width: 100%;
  display: grid;
  place-items: center;
  row-gap: 3em;
  padding: 2em;
}
.alertTittle {
  position: absolute;
  top: 70%;
  left: 13%;
  width: 80%;
  font-size: 14px;
  color: var(--color_1);
}
.alertImgContainer {
  position: relative;
  text-align: center;
}
.researchpaper_wrap {
  width: 100%;
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  row-gap: 1em;
}
.reserachPaper_child_wrap {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  padding-left: 2em;
  padding-bottom: 2em;
  padding-top: 1em;
}
.reserachPaper_child_wrap h3 {
  color: var(--color_1);
  font-family: Montserrat;
  font-size: clamp(24px, 2.2vw, 36px);
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
.reserachPaper_child_wrap p {
  width: 90%;
  line-height: 140%;
  text-align: justify;
  letter-spacing: 1px;
}
.caseAlertPage .resource_wrap {
  grid-template-columns: 40% 50%;
  gap: 2em;
}
.underlineText {
  text-decoration: underline;
}
.caseModal_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5em;
  row-gap: 1em;
}
.caseModal_wrap p,
.caseModal_wrap i,
.caseModal_wrap b {
  text-align: start;
  font-size: 12px;
}
.caseModal_wrap i {
  display: block;
}
.chatConatiner {
  width: 100%;
  border-radius: 16px;
  background: #d5dbdb;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
  /* height: 70vh; */
  position: relative;
  overflow-y: scroll;
  padding: 1em 4em;
  display: flex;
  flex-direction: column;
}
.chatConatiner p {
  padding: 0.5em;
  width: max-content;
  border-radius: 8px;
  background: #fff;
}
.chatinput_wrap {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: baseline;
}
.chatinput_wrap input {
  height: 50px;
  border: none;
  padding: 0.5em 1em;
  width: 100%;
  border-radius: 16px;
  border: 2px solid #005186;
  background: #fff;
}
.chatinput_wrap span {
  position: absolute;
  right: 10px;
  cursor: pointer;
}
.usertext,
.chattext {
  position: relative;
  padding: 1em 0;
}
.usertext p,
.chattext p {
  max-width: 90%;
}
.usertext {
  display: flex;
  justify-content: flex-end;
}
.typing_effect {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}
.pageBody {
  display: grid;
  width: 100%;
  grid-template-columns: 30% 70%;
  padding: 1em 2em;
}
.pageBody > div:first-child {
  border-right: 2px solid #cbcbcb;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.pageBody > div {
  padding: 0.5em 1em;
}
.pageBody > div:first-child .card_oped:nth-child(2) {
  border-top: 2px solid #cbcbcb;
  border-bottom: 2px solid #cbcbcb;
}
.pageBody > div:nth-child(2) .card_oped:nth-child(2) {
  border-top: 2px solid #cbcbcb;
  border-bottom: 2px solid #cbcbcb;
}
.pageBody > div:first-child .card_oped:nth-child(3) {
  border-bottom: 2px solid #cbcbcb;
}
.card_oped {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 1em;
}
.card_oped img {
  width: 100%;
  /* aspect-ratio: 16/9; */
  object-fit: cover;
}
.card_oped h3 {
  color: #005186;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0.5em;
}
.oped_flex {
  display: flex;
  width: 100%;
}
.oped_flex > a {
  border: none !important;
}
.card_oped p {
  width: 100%;
  text-wrap: wrap;
  padding: 0.5em;
  color: #262626;
}
.opedPage a {
  text-decoration: none;
}
.opedPage .landing_wrap,
.clausePage .landing_wrap,
.chat360page .landing_wrap,
.extraPage .landing_wrap {
  height: 30vh;
  row-gap: 0;
}
.templatePage .landing_wrap {
  height: 18vh;
  row-gap: 0;
}

.templatePage {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f4f4f4;
}

.landing_wrap {
  text-align: center;
  margin-bottom: 20px;
}

.preview-container {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  min-height: 200px;
  overflow: auto;
}
.preview-options {
  margin: auto;
  width: 800px;
}
.preview-box {
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.dropdown-wrap {
  position: relative;
  display: inline-block;
  margin: 20px 0;
}

.blue_btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.blue_btn:hover {
  background-color: #0056b3;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 150px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content button {
  color: black;
  padding: 10px 15px;
  text-align: left;
  text-decoration: none;
  display: block;
  width: 100%;
  background: none;
  border: none;
  cursor: pointer;
}

.dropdown-content button:hover {
  background-color: #ddd;
}

.dropdown-wrap:hover .dropdown-content {
  display: block;
}

.oped_pagebar {
  display: flex;
  width: 30%;
  max-width: 200px;
  background-color: var(--color_2);
}
.oped_pagebar a {
  border: 1px solid #fff;
  padding: 0.5em;
  flex: 1;
}
.opedPage {
  display: grid;
  place-items: center;
  padding-bottom: 2em;
}
@media screen and (max-width: 600px) {
  .opedPage .landing_wrap,
  .clausePage .landing_wrap,
  .chat360page .landing_wrap,
  .extraPage .landing_wrap {
    height: 100vh;
    row-gap: 0;
  }
  .pageBody {
    grid-template-columns: 100%;
    padding: 0.5em;
  }
  .pageBody .card_oped,
  .pageBody > div {
    border: none !important;
  }
  .oped_flex {
    flex-direction: column;
  }
  .chatConatiner {
    padding: 1em;
  }
  .researchpaper_wrap {
    padding: 0.5em;
  }
  .poadcast_main_wrap {
    row-gap: 2em;
  }
  .settel_wrap h2 {
    text-align: center;
    font-size: 24px;
  }
  .settel_wrap p {
    text-align: center;
    font-size: 16px;
  }
  .settel_wrap {
    padding: 1em;
  }
  .caseAlertPage .resource_wrap {
    grid-template-columns: 100%;
  }
  .alertTittle {
    font-size: 62%;
  }
  .odrtvnav li {
    font-size: 16px;
  }
  .newsletter_wrap > div {
    flex-direction: column;
    align-items: center;
  }
}
