.container-template {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  margin-bottom: 50px;
  width: 100%;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.heading_1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #005186;
  text-align: center;
  margin-bottom: 3rem;
}

.grid_1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.0rem;
  height: 380px;
  width: 100%;
  margin-bottom: 20px;
}

@media (max-width: 1024px) {
  .grid_1 {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
}

@media (max-width: 768px) {
  .grid_1 {
    grid-template-columns: 1fr;
    gap: 1rem;
    height: auto;
    width: 90%;
  }
}

.card_1 {
  display: flex;
  height: 200px;
  width:90%;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
  background-color: rgb(188, 212, 230);
  border-radius: 1.0rem;
  cursor: pointer;
  /* transition: all 0.2s ease; */
  transform: scale(1);
  /* box-shadow: none; */
  text-decoration: none;
  transition: transform 0.3s ease;
  box-shadow: 0.3s ease;  
}

.card_1-active {
  transform: scale(1.02);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.link_1 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 2rem;
  font-weight: 600;
  color: rgb(0, 84, 166);
}

.icon {
  height: 20px;
  width: 20px;
}

.card_1-icon {
  height: 40px;
  width: 40px;
  color: rgb(0, 84, 166);
  transition: transform 0.2s ease;
}

.card_1:hover {
  /* transform: translateX(5px);  */
  transform: scale(1.05);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.back-button {
  position: absolute; 
  top: 130px; 
  left: 20px; 
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #005186;
  text-decoration: none;
  height: 70px;
  width: 70px;

}

.back-icon {
  margin-right: 8px; 
  width: 48px; 
  height: 48px;
  
}

