.aboutPageSection{
     display: flex;
     padding: 1em 2em 4em 2em;
}
.leftSection{
    width:50%;
    display: grid;
    place-items: center;
}
.rightSection{
     width: 50%;
      display: flex;
      flex-direction: column;
      row-gap: 1em;
       justify-content: center;
}
.leftSection img{
     width: 75%;
}

 .rightSection p{
    color: #1E1E1E;
text-align: justify;
font-family: Montserrat;
font-size: 1.3vw;
font-style: normal;
font-weight: 500;
line-height: 160%;
width: 80%;
 }
 .homepage .sliderDots{
  padding-top: 2em;
 }
 .core_value_wrap{
    display: flex;
    flex-wrap: wrap;
    padding: 0 2em;
     align-items: center;
     justify-content: center;
     gap: 2em;
}
.greyColor_text{
    color: #454545 !important;
}
.milestoneCard{
    border-radius: 16px;
    border: 4px solid #FBB04C;  
    width: 80%;
    padding: 1em;
    display: flex;
    gap: 2.5em;
    align-items: center;
}
.milestonesection{
     display: grid;
     place-items: center;
}
.milestoneCard h2{
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    padding-left: .5em;
}
.milestoneCard p{
    color: #FFF;
font-family: Montserrat;
font-size:1.5vw;
font-style: normal;
font-weight: 700;
line-height: 160%;
}
.landing_wrap h3{
    text-align: center;
    color: var(--color_1);
     font-size: 2em;
font-family: Montserrat;
font-weight: 600;
line-height: 160%;
}
.career_card{
    border-radius: 16px;
border: 2px solid #FBB04C;
/* width: 300px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 1.5em;
  min-height: 160px;
}
 .career_card h3{
    color: #005186;
font-family: Montserrat;
font-size: 1.5vw;
position: relative;
font-style: normal;
font-weight: 600;
line-height: 160%;
letter-spacing: -1.8px; 
}
.career_card p {
     padding-right: 2em;
     padding-top: 1em;
}
.job_card_wrap{
     display: flex;
     flex-wrap: wrap;
     gap: 2em;
     align-items: center;
     justify-content: center;
}
.arrowsvg_career{
    position: absolute;
    top: 55%;
    right: 15px;
}
.life_img_wrap{
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
}
.life_img_wrap img{
     width: 70%;
}
.core_mobile_card h3{
    color: var(--Presolv360-Blue, #005186);
font-family: Montserrat;
font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: -1.2px; 
padding-bottom: 1em;
}
.core_mobile_card p {
    color:  #454545;
text-align: justify;
font-family: Montserrat;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 160%; /* 25.6px */
letter-spacing: -0.8px; 
padding-bottom: 2em;
 }
 .milestoneCard hr{
  height: 30px;
  width: 5px;
  color: var(--color_2);
  background-color: var(--color_2);
  outline: none;
  border: none;
 }
.Mobile_core_wrap{
     width: 100%;
     padding: 1.5em;
}
.core_mobile_card {
     border-bottom: 2px solid #A5A5A5 ;
     padding-top: 2em;
}
.Mobile_milestone_wrap .core_mobile_card h3,.Mobile_milestone_wrap .core_mobile_card p{
    color: #FFF;
}

.offeringpage .sliderDots{
    padding-top: 2em;
}
.caseStudy_wrap{
     width: 100%;
     display: flex;
     padding-bottom: 3em;
} 
.caseStudy_img img{
  width: 70%;
}
.caseStudy_wrap h2{
    color: var(--Presolv360-Blue, #005186);
font-family: Montserrat;
font-size: 2.5vw;
font-style: normal;
font-weight: 600;
line-height: 140%;
padding-bottom: .5em;
 }
.caseStudy_img{  
      width: 50%;
      display: grid;
      place-items: center;
}
.caseStudy_content{
    width: 50%;
    /* padding: 2em;
    padding-top: 3em; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    padding-left: 4em;

 }
 /* .caseStudy_content button{
    margin-left: 1em;
 } */


.council{
   justify-content: center;
      flex-wrap: nowrap;
      gap: 3em;

}
.council > div{
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   flex: 1;
}

 .card {
    width: 280px;
    height: 360px;
    border-radius: 15px;
    padding: 1.5rem;
    background: white;
    position: relative;
    display: flex;
    align-items: flex-end;
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  }
  .card:hover {
    transform: translateY(20px);
  }
  .card:hover:before {
    opacity: 1;
  }
  .card:hover .info {
    opacity: 1;
    transform: translateY(0px);
  }
  .card:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
    transition: 0.5s;
    opacity: 0;
  }
  .card img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
  }
  .card .info {
    position: relative;
    z-index: 3;
    color: white;
    opacity: 0;
    transform: translateY(30px);
    transition: 0.5s;
  }
  .card .info h1 {
    margin: 0px;
  }
  .card .info p {
    letter-spacing: 1px;
    font-size: 15px;
    margin-top: 8px;
    text-align: center;
  }
  .card .info button {
    padding: 0.6rem;
    outline: none;
    border: none;
    border-radius: 3px;
    background: white;
    color: black;
    font-weight: bold;
    cursor: pointer;
    transition: 0.4s ease;
  }
  .card .info button:hover {
    background: dodgerblue;
    color: white;
  }


 @media screen and (max-width:600px) {
    .aboutPage .landing_wrap h1{
        padding: 0em 1em;
    }
    .aboutPage .landing_wrap h3{
         font-size: 20px;
         padding: 0em 1em;
    }
    .aboutPageSection{
        flex-direction: column;
    }
    .rightSection,.leftSection{
        width: 100%;
    }
    .rightSection p{
         font-size: 16px;
         width: 100%;
         padding-top: 2em;
    }
    .aboutPage .firstBreak{
        background-image: url(../Images/About\ Page\ Mobile\ Gradient.png);
    }
    .aboutPage .section_heading{
        padding-top: 2em;
    }
    .careerPage .landing_wrap h3 {
        font-size: 20px;
    }
    .careerPage .section_heading{
         padding-top: 4em;
         padding-bottom: 4em;
    }
    .career_card h3{
        font-size: 20px;
    }
    .life_img_wrap img{
        width: 90%;
    }
    .life_img_wrap{
        padding-bottom: 2em;
    }
 }