.about_mobile_section{
    padding: 1em;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   row-gap: 1em;
   text-align: center;
}
.about_mobile_section h3{
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    color: var( --color_1);
    line-height: 160%; /* 32px */
    letter-spacing: -1px;   
}
.slick-prev::before,.slick-next::before{
    color: var(--color_2)!important;
}