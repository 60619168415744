.extra_section_wrap{
    padding: 2em;
    width: 90%;
    margin: auto;
     display: flex;
     flex-direction: column;
     row-gap: 1em;
}
.extra_section_wrap h3{
    color: var(--color_1);
    font-size: clamp(24px,2.2vw,36px);
    padding: 1em 0;
}
.extra_section_wrap h5{
     letter-spacing: 1px;
     line-height: 140%;
     font-size: clamp(18px,1.3vw,28px);
}
.extra_section_wrap p{
  line-height: 140%;
}
.cookies ul  li{
  list-style: disc;
  line-height: 140%;
}

  .faqPage .accordion__button{
    color:#005186;
text-overflow: ellipsis;
font-family: Montserrat;
font-size: clamp(18px,1.3vw,28px);
font-style: normal;
font-weight: 600;
line-height: normal;
  }
  .faqPage .accordion__panel p {
    padding-bottom: 1em;
    line-height: 140%;
  } 
  .faqPage .accordion__panel ul{
    padding-left: 1em;
  }
  .faqPage .accordion__panel ul li {
     list-style: disc;
  }
  .faqPage .accordion__item{
    margin: .5em 0;
  }
  .copyboard{
     width:100% ;
 
     padding: 1em;
     
  }
  .copyboard p {
    padding: 1em 0;
  }
 .copy_wrap{
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    border-radius: 8px;
    background-color: #E6E6E6;
 }
 .boldBullet{
   list-style: disc;
   font-weight: 700;
    margin-left: 1em;
 }
@media screen and (max-width:600px) {
  .faqPage{
    padding: .5em;
    width: 100%;
  } 
    .extra_section_wrap{
        padding: 1em 1.5em;
    }
.cookies{
    width: 100%;
    padding: 1em;
}
}