.offer_advantage_main_wrap{
      width: 100%;
      display: flex;
      height: 400px;
}
.offer_adv_title{
    border: 12px solid var(--Presolv360-Blue, #005186);
    width: 50%;
    display: grid;
    place-items: center;
}
.offer_adv_title h2{
    color: var(--Presolv360-Blue, #005186);
text-align: center;
font-family: Montserrat;
font-size: 2.5vw;
font-style: normal;
font-weight: 800;
line-height: 160%; /* 76.8px */
letter-spacing: 3.36px;
text-transform: uppercase;
}
.offer_adv_desc{
       background-color: #005186;
       width: 50%;
       display: grid;
       place-items:center ;
}
.offer_adv_desc p{
    color: #FFF;
font-family: Montserrat;
font-size: 2vw;
font-style: normal;
font-weight: 600;
line-height: 160%; /* 76.8px */
     width: 80%;
}
.fadeinout
{
  animation: fadeinout 5s infinite;
}

@keyframes fadeinout
{
  0%{
    opacity:0;
  }
  50%
  {
    opacity:1;
  }
  100%{
    opacity:0;
  }
}
 @media screen and (max-width:600px) {
    .caseStudy_wrap{
         flex-direction: column;
        align-items: center;
        row-gap: 1em;
    }
    .caseStudy_content{
        width: 100%;
        padding: 1em;
    text-align: center;
    }
    .caseStudy_img{
        width: 100%;
    }
    .caseStudy_wrap h2{
        font-size: 24px;
    }
    .case2{
         flex-direction: column-reverse;
    }
 }